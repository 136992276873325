<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="clientpaymentlist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bb1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Client Payment</h5>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 5%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Client Name" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.bu3 ? data.bu3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column field="paymentdate" header="Payment Date Time" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>{{ format_timestamp(data.bu13) }}</div>
                        </template>
                    </Column>
                    <Column header="Payment Mode" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <span v-if="data.bu5 == 1" :class="'plan-badge status-free'">Cheque</span>
                            <span v-if="data.bu5 == 2" :class="'plan-badge status-paid'">NEFT/RTGS</span>
                        </template>
                    </Column>
                    <Column header="Amount" headerStyle="width: 15%" class="p-text-right">
                        <template #body="{ data }">
                            <div>{{ formatCurrency(data.bu4 ? data.bu4 : '0') }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Payment Status" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <span v-if="data.bu15 == 1" :class="'plan-badge status-free'">Submitted </span>
                            <span v-if="data.bu15 == 2" :class="'plan-badge status-paid'">Approved</span>
                            <span v-if="data.bu15 == 3" :class="'plan-badge status-paid'">Canceled</span>
                        </template>
                    </Column>
                    <Column field="name" header="User Name" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.bu10 ? data.bu10 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 5%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="updatePaymentsFromClientOpenDialog(data)" :disabled="data.bu15 !=1 "/>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add billing plan dialog start here -->
    <Dialog v-model:visible="editClientPaymentStatus" :style="{ width: '800px' }" :modal="true" class="p-fluid">
        <Toast />
        <template #header>
            <span class="p-dialog-title" >Update Client Payment Status</span>
        </template>
        <div style="position: relative" v-if="!showloaderpage">

            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="clientname">
                            Client name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="clientname" class="p-text-capitalize" v-model.trim="clientname" required="true"
                            maxlength="20" disabled/>

                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label for="amount">
                            Amount
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="amount" class="p-text-capitalize" v-model.trim="amount" @keypress="onlyNumber"
                            required="true" maxlength="8" disabled/>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label>Plan Charges <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="cheque" name="option" v-bind:value="1" v-model="paymentmode"
                                :checked="paymentmode == 1" disabled/>
                            <label for="cheque" class="p-mr-2">Cheque</label>
                            <RadioButton id="NEFT" name="option" v-bind:value="2" v-model="paymentmode"
                                :checked="paymentmode == 2" disabled />
                            <label for="NEFT" class="p-mr-2">NEFT/RTGS</label>
                        </div>
                    </div>


                    <div class="p-field p-col-12 p-md-12">
                        <label>Payment Status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="byClient" name="option" v-bind:value="1" v-model="status"
                                :checked="status == 1" />
                            <label for="byClient" class="p-mr-2">Submitted by Client User</label>
                            <RadioButton id="approved" name="option" v-bind:value="2" v-model="status"
                                :checked="status == 2" />
                            <label for="approved" class="p-mr-2">Checked payment in account and approve</label>
                            <RadioButton id="cancel" name="option" v-bind:value="3" v-model="status"
                                :checked="status == 3" />
                            <label for="cancel" class="p-mr-2">Cancel</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showloaderpage" style="height: 200px; position: relative">
            <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updatePaymentsFromClientStatus" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add billing plan dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
// import router from '@/router';
import moment from "moment";
// import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
    inject: ['global'],
    data() {
        return {
            v$: useValidate(),
            clientpaymentlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            editClientPaymentStatus: false,
            paymentmode: '',
            amount: '',
            clientname: '',
            status: '',
            showLoader: false,
            submitted: false,
            showloaderpage: false,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getPaymentsFromClientDetails();
    },
    // validations() {
    //     return {
    //         paymentmode: { required: helpers.withMessage('Please select plan charges', required) },
    //         amount: { required: helpers.withMessage('Please enter monthly cost', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
    //         clientname: { required: helpers.withMessage('Please enter plan name', required) },
    //     };
    // },
    methods: {
        getPaymentsFromClientDetails(ev) {
            this.loading = true;
            this.ApiService.getPaymentsFromClientDetails(ev).then((data) => {
                if (data.status == 200) {
                    this.clientpaymentlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.clientpaymentlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getPaymentsFromClientDetails({page_no:this.page_no});
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        updatePaymentsFromClientOpenDialog(e) {
            this.editClientPaymentStatus = true;
            this.id = e.bu1;
            this.clientname = e.bu3;
            this.amount = e.bu4;
            this.paymentmode = e.bu5;
            this.status = e.bu15;
        },

        updatePaymentsFromClientStatus() {

            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['bu1'] = this.id;
            fields['bu15'] = this.status;
            this.showLoader = true;
            this.ApiService.updatePaymentsFromClientStatus(fields).then((items) => {
                if (items.status == 200) {
                    this.loading = false;
                    this.showLoader = false;
                    var successMsg = items.message;
                    this.editClientPaymentStatus = false;
                    this.$toast.add({ severity: 'success', summary: 'Plan Added Successful', detail: successMsg, life: 3000 });
                    this.getPaymentsFromClientDetails();
                } else {
                    this.loading = false;
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },


        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY");

                // return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
